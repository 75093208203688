import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { useForm } from 'antd/lib/form/Form'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import FormYoloPago from '../../components/FormYolopago'
import ModalResponse from '../../components/Shared/ModalResponse'
import ModalResult from '../../components/Shared/ModalResult'
import { StyledButtonPrimary } from '../../components/Shared/Button/styledComponent'
import { CREATE_AFFILIATION_YOLOPAGO } from '../../graphql/mutation/yolopago'
import { GET_PROFILE } from '../../graphql/queries/profile'
import {
  GET_COUNTY_BY_POSTAL_CODE,
  GET_TERMS_YOLOPAGO,
  GET_YOLOPAGO_DATA
} from '../../graphql/queries/yolopago'
import {
  GET_PAYMENT_STATUS_AFFILIATION,
  GET_PAYMENT_AFFILIATION_PROVIDER
} from '../../graphql/queries/paymentAffiliation'
import UserTypes from '../../types/UserTypes'
import YoloPagoTypes from '../../types/YoloPagoTypes'
import Loading from '../../components/Shared/Loading'
import { Result, Upload } from 'antd'
import { toast } from 'react-toastify'
import { navigate } from 'gatsby'
import IntegrationTypes from '../../types/IntegrationTypes'
import { StyledSubtitle } from './styledComponents'
import AWS from 'aws-sdk'
import { fetchAuthSession } from 'aws-amplify/auth'
import { UploadResult } from '../../types/UploadFilesTypes'

type propsResult = {
  isModalVisible: boolean
  content: any
}

const resultInitialState: propsResult = {
  isModalVisible: false,
  content: <></>
}

// Configura AWS SDK con tus credenciales de Cognito
AWS.config.update({
  region: 'us-east-1',
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: `${process.env.GATSBY_AMPLIFY_USER_POOL_WEB_CLIENT_ID_EMPRESARIO}`,
    IdentityId: `${process.env.GATSBY_AMPLIFY_USER_POOL_ID_EMPRESARIO}`
  })
})

enum EnumAllowedFileNames {
  INE = 'INE',
  INE_BACK = 'INE_BACK',
  COMPROBANTEDOMICILIO = 'COMPROBANTE_DOMICILIO',
  EDOCUENTA = 'EDO_CUENTA',
  ACTA_CONSTITUTIVA = 'ACTA_CONSTITUTIVA',
  HACIENDA = 'HACIENDA'
}

const YolopagoContainer: React.FC = () => {
  const noCache: any = { fetchPolicy: 'no-cache' }
  const [errorYP, seterrorYP] = useState(false)
  const [loading, setLoading] = useState(false)
  const [disabledForm, setDisabledForm] = useState(true)
  const [result, setResult] = useState(resultInitialState)
  const [help, setHelp] = useState(resultInitialState)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [files, setFiles] = useState({
    fileNameINE: '',
    fileNameINEBack: '',
    fileNameAddress: '',
    fileNameBank: '',
    fileNameConstitutive: '',
    fileNameHacienda: ''
  })
  const [documentList, setDocumentList] = useState(
    Array<YoloPagoTypes.DocumentListYoloPago>
  )
  const [formYoloPago] = useForm()
  const [
    createAffiliationYP,
    { data: createAffiliationYPData, loading: createAffiliationYPLoading }
  ] = useMutation(CREATE_AFFILIATION_YOLOPAGO, noCache)
  const [refetchCountyId, { data: countyData }] = useLazyQuery(
    GET_COUNTY_BY_POSTAL_CODE
  )
  const { data: profileData, loading: profileLoading } =
    useQuery<UserTypes.Partner>(GET_PROFILE)
  const {
    data: yoloData,
    loading: yoloLoading,
    error: yoloError
  } = useQuery(GET_YOLOPAGO_DATA)
  const { data: termsYPData, error: termsYPError } =
    useQuery(GET_TERMS_YOLOPAGO)

  const { data: paymentAffiliationStatusData, loading: affStatusLoading } =
    useQuery<IntegrationTypes.PaymentAffiliationStatus>(
      GET_PAYMENT_STATUS_AFFILIATION,
      noCache
    )

  const { data: paymentProvidersData, loading: providersLoading } =
    useQuery<IntegrationTypes.PaymentProviders>(
      GET_PAYMENT_AFFILIATION_PROVIDER,
      noCache
    )

  const yoloPagoProviderID = paymentProvidersData?.getPaymentProviders.find(
    (provider: any) => provider.Description === 'YoLoPago'
  )?.PaymentProviderID

  const yoloPagoAffiliation =
    paymentAffiliationStatusData?.getPaymentAffiliationStatus.find(
      (provider: any) => provider.PaymentProviderID == yoloPagoProviderID
    )

  const parseYoloPagoAffiliation = {
    ...yoloPagoAffiliation,
    MessageProcess: yoloPagoAffiliation?.MessageProcess
      ? JSON.parse(yoloPagoAffiliation?.MessageProcess)
      : { message: '' }
  }

  const [typePerson, setTypePerson] = useState(1)
  formYoloPago.setFieldsValue({ personType: typePerson })

  useEffect(() => {
    if (yoloError) {
      seterrorYP(true)
    }

    if (termsYPError) {
      seterrorYP(true)
    }
  }, [yoloError, termsYPError])

  useEffect(() => {
    if (createAffiliationYPData && !createAffiliationYPLoading) {
      setLoading(false)
      setResult({
        isModalVisible: true,
        content: (
          <ModalResult
            key={`success-${Math.random()}`}
            status={'info'}
            subTitle={
              <p style={{ color: 'black' }}>
                {createAffiliationYPData.createModeloramaYP.message}
              </p>
            }
            extra={
              <StyledButtonPrimary
                type="button"
                onClick={handleCancel}
                key={'Accept-create'}
              >
                Aceptar
              </StyledButtonPrimary>
            }
          />
        )
      })
    }
  }, [createAffiliationYPData, createAffiliationYPLoading])

  useEffect(() => {
    if (countyData && countyData?.getCountyByPostalCode?.content === null) {
      setResult({
        isModalVisible: true,
        content: (
          <ModalResult
            key={`error-${Math.random()}`}
            status={'warning'}
            subTitle={
              <>
                <StyledSubtitle style={{ color: '#FF8C07' }}>
                  Error al obtener información del Código Postal
                </StyledSubtitle>
                <StyledSubtitle style={{ color: 'black' }}>
                  El Código Postal de tu Modelorama es incorrecto, comunícate
                  con soporte al <b>WhatsApp 55 35784444</b> para corregirlo y
                  puedas continuar con tu registro
                </StyledSubtitle>
              </>
            }
            extra={
              <StyledButtonPrimary
                type="button"
                onClick={handleCancel}
                key={'Accept-create'}
              >
                Aceptar
              </StyledButtonPrimary>
            }
          />
        )
      })
    }
  }, [countyData])

  const personChange = (e: any) => {
    setTypePerson(e.target.value)
  }

  const onFinish = async (data: any) => {
    const commerce_name = profileData?.getProfile.stores.find(
      (x) => x.storeID === data.storeID
    )?.name
    setLoading(true)
    const dataY: YoloPagoTypes.Data = {
      activities_id: 1,
      county_id: data.countyId,
      clabe: data.clabe,
      commerce_name: `${commerce_name}_${data.storeID}`,
      curp: data.curp,
      phone: data.mobilePhone,
      estimatedMonthlySales_id: data.sales,
      street: getAddressByStoreId(data.storeID),
      email: data.email,
      ap_paterno: data.middleName,
      ap_materno: data.familyName,
      name: data.name,
      documentList: documentList,
      terminalList: getTerminalsByStoreId(data.storeID),
      commerce_type: typePerson === 1 ? 'Persona Física' : 'Persona Moral',
      rfc: data.rfc,
      acepto_terminos_y_condiciones: data.remember,
      fecha_hora_terminos_y_condiciones: moment().format('DD/MM/yyyy HH:mm:ss')
    }
    // eslint-disable-next-line no-console
    console.log('dataY: ', dataY)
    createAffiliationYP({
      variables: dataY
    }).catch((error) => {
      toast.error(error)
      setLoading(false)
      setResult({
        isModalVisible: true,
        content: (
          <ModalResult
            key={`error-${Math.random()}`}
            status={'error'}
            subTitle={
              <>
                <StyledSubtitle style={{ color: '#c41a1a' }}>
                  Error al cargar archivo
                </StyledSubtitle>
                <StyledSubtitle style={{ color: 'black' }}>
                  La suma de tus archivos que deseas cargar debe ser menor a 2
                  megas
                </StyledSubtitle>
              </>
            }
            extra={
              <StyledButtonPrimary
                type="button"
                onClick={handleCancel}
                key={'Accept-create'}
              >
                Aceptar
              </StyledButtonPrimary>
            }
          />
        )
      })
    })
  }

  const handleClabeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = e.target

    const re = /^[0-9\b]+$/

    if (inputValue === '' || re.test(inputValue)) {
      formYoloPago.setFieldsValue({ clabe: inputValue })
    } else {
      formYoloPago.setFieldsValue({ clabe: '' })
    }
  }

  const onChangeStore = async (storeID: string) => {
    const storeSelected = profileData?.getProfile.stores.find(
      (x) => x.storeID === storeID
    )

    // eslint-disable-next-line no-console
    console.log('storeSelected: ', storeSelected)

    if (storeSelected) {
      await refetchCountyId({
        variables: { zipCode: storeSelected.postalCode }
      })
      const county_id =
        countyData?.getCountyByPostalCode?.content.find(
          (e) => e.name === storeSelected.neighborhood
        )?.id || undefined
      formYoloPago.setFieldsValue({
        neighborhoodStore: storeSelected.neighborhood,
        extNumberStore: storeSelected.buildingNumber,
        intNumberStore: storeSelected.complement,
        postalCode: storeSelected.postalCode,
        street: storeSelected.street,
        city: storeSelected.city,
        state: storeSelected.state,
        countyId: county_id
      })
    } else {
      formYoloPago.setFieldsValue({
        neighborhoodStore: undefined,
        extNumberStore: undefined,
        intNumberStore: undefined,
        postalCode: undefined,
        street: undefined,
        city: undefined,
        state: undefined,
        countyId: undefined
      })
    }
  }

  /**
   * Uploads a file to an AWS S3 bucket and updates the state with the file name.
   * @param file - The file to be uploaded.
   * @param documentId - The ID of the document.
   * @param type - The type of the document.
   * @returns An object containing the file path and name.
   */
  const handleUpload = async (
    file: File,
    documentId: number,
    type: string
  ): Promise<UploadResult | undefined> => {
    if (!file) {
      alert('Selecciona un archivo para cargar.')
      return
    }
    const partnerId = profileData?.getProfile.partnerID
    const newFileName = renameFiles(file.name, documentId)
    const extensionFileType = file.type.split('/')[1]

    switch (type) {
      case 'ine':
        setFiles({ ...files, fileNameINE: newFileName })
        break
      case 'ine-back':
        setFiles({ ...files, fileNameINEBack: newFileName })
        break
      case 'address':
        setFiles({ ...files, fileNameAddress: newFileName })
        break
      case 'bank':
        setFiles({ ...files, fileNameBank: newFileName })
        break
      case 'constitutive':
        setFiles({ ...files, fileNameConstitutive: newFileName })
        break
      case 'hacienda':
        setFiles({ ...files, fileNameHacienda: newFileName })
        break
    }
    const credentials = await fetchAuthSession({ forceRefresh: true })

    AWS.config.region = process.env.GATSBY_AMPLIFY_REGION_EMPRESARIO
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: `${process.env.GATSBY_AMPLIFY_IDENTITY_POOL_WEB_CLIENT_ID_EMPRESARIO}`,
      Logins: {
        [`cognito-idp.${AWS.config.region}.amazonaws.com/${process.env.GATSBY_AMPLIFY_USER_POOL_ID_EMPRESARIO}`]:
          String(credentials.tokens?.idToken?.toString())
      }
    })

    const s3 = new AWS.S3()

    const params = {
      Bucket: `${process.env.GATSBY_AMPLIFY_S3_BUCKET_EMPRESARIOS_WEB_YOLOPAGO_FILES}`,
      Key: `${partnerId}/${newFileName}.${extensionFileType}`,
      Body: file
    }

    try {
      const data = await s3.upload(params).promise()
      // eslint-disable-next-line no-console
      console.log('Archivo subido exitosamente')

      return {
        file_path: data.Key,
        file_name: `${newFileName}.${extensionFileType}`
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Error al subir el archivo a S3:', err)
      return undefined // Devolver undefined en caso de error
    }
  }

  const normFile = (e: any, id: number, type: string) => {
    if (e.fileList.length > 0) {
      createFileBase64(e.file, id, type)
    }
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const createFileBase64 = async (
    file: File,
    documentId: number,
    type: string
  ) => {
    if (!result) {
      formYoloPago.setFieldsValue({ [type]: undefined })
      formYoloPago.setFields([
        {
          name: type,
          errors: [
            'Sube un archivo que se encuentre guardado en tu dispositivo'
          ]
        }
      ])
      return
    }

    const resultFile = await handleUpload(file, documentId, type)

    const isInList = documentList.some(
      (item) => item.documentTypes_id === documentId
    )
    const newDocuments = documentList
    if (isInList) {
      const indexOfFile = documentList.findIndex(
        (item) => item.documentTypes_id === documentId
      )
      newDocuments.splice(indexOfFile, 1)
    }
    newDocuments.push({
      documentTypes_id: documentId,
      file_path: resultFile?.file_path,
      file_name: resultFile?.file_name
    })

    setDocumentList(newDocuments)
  }

  const renameFiles = (file: any, documentId: number) => {
    let fileName = file.name

    switch (documentId) {
      case 1:
        fileName = EnumAllowedFileNames.INE
        return fileName
      case 2:
        fileName = EnumAllowedFileNames.INE_BACK
        return fileName
      case 3:
        fileName = EnumAllowedFileNames.COMPROBANTEDOMICILIO
        return fileName
      case 4:
        fileName = EnumAllowedFileNames.EDOCUENTA
        return fileName
      case 5:
        fileName = EnumAllowedFileNames.ACTA_CONSTITUTIVA
        return fileName
      case 6:
        fileName = EnumAllowedFileNames.HACIENDA
        return fileName
    }
  }

  const getAddressByStoreId = (storeID: string) => {
    return profileData?.getProfile.stores.find((x) => x.storeID === storeID)
      .formatAddress
  }

  const getTerminalsByStoreId = (storeID: string) => {
    const devices = profileData?.getProfile.stores.find(
      (x) => x.storeID === storeID
    )?.device
    const terminalList: string[] = []
    devices?.forEach((element) => {
      terminalList.push(element.deviceID)
    })
    return terminalList
  }

  const handleBeforeUplad = (file: any, type: string) => {
    const allowedTypes = ['image/jpg', 'image/jpeg', 'application/pdf']
    if (!allowedTypes.includes(file.type)) {
      formYoloPago.setFields([
        {
          name: type,
          errors: ['Solo se permiten archivos de tipo PDF, JPG o JPEG']
        }
      ])
      return Upload.LIST_IGNORE
    }

    const isLtm6M = file.size / 1024 / 1024 < 2
    if (!isLtm6M) {
      formYoloPago.setFields([
        {
          name: type,
          errors: ['El archivo pesa más de 2MB']
        }
      ])
      return Upload.LIST_IGNORE
    }

    return false
  }

  const onFieldsChange = () => {
    const fields = [
      'clabe',
      'remember',
      'storeID',
      'ine',
      'address',
      'bank',
      'sales'
    ]

    const personType = formYoloPago.getFieldValue('personType')
    if (personType === 2) {
      fields.push('hacienda')
    }

    setDisabledForm(
      !!formYoloPago.getFieldsError().filter(({ errors }) => errors.length)
        .length
    )

    fields.forEach((element) => {
      if (!formYoloPago.getFieldValue(element)) {
        setDisabledForm(true)
      }
    })
  }

  const onClickTermsAndConditions = () => {
    setResult({
      isModalVisible: true,
      content: (
        <ModalResult
          key={`success-${Math.random()}`}
          status={'info'}
          subTitle={
            <p style={{ color: 'black' }}>
              {termsYPData.getTermsAndConditions.content.term_and_conditions}
            </p>
          }
          extra={
            <StyledButtonPrimary
              type="button"
              onClick={handleCancel}
              key={'Accept-termis'}
            >
              Aceptar
            </StyledButtonPrimary>
          }
        />
      )
    })
  }

  const handleCancel = () => {
    setResult(resultInitialState)
    if (
      createAffiliationYPData?.createModeloramaYP?.status === 'Ok' ||
      countyData?.getCountyByPostalCode?.content === null
    ) {
      navigate('/interaction')
    }
  }

  const onHelpClose = () => {
    setHelp({ isModalVisible: false, content: <></> })
  }

  const onHelpChange = (content: any) => {
    setHelp({
      isModalVisible: true,
      content: content
    })
  }

  if (profileLoading || yoloLoading || providersLoading || affStatusLoading) {
    return <Loading />
  }

  return (
    <>
      {!loading && !errorYP && (
        <>
          <FormYoloPago
            form={formYoloPago}
            onFinish={onFinish}
            partnerData={profileData}
            yoloData={yoloData}
            onChangeStores={onChangeStore}
            typePerson={typePerson}
            onChangeTypePerson={personChange}
            normFile={normFile}
            onClickTermsAndConditions={onClickTermsAndConditions}
            yoloPagoAffiliation={parseYoloPagoAffiliation}
            help={help}
            onHelpClose={onHelpClose}
            onHelpChange={onHelpChange}
            onFieldsChange={onFieldsChange}
            disabled={disabledForm}
            handleBeforeUplad={handleBeforeUplad}
            files={files}
            handleClabeChange={handleClabeChange}
            countyData={countyData}
          />
          <ModalResponse
            isModalVisible={result.isModalVisible}
            content={result.content}
            closable={false}
            onCancel={handleCancel}
          />
        </>
      )}
      {!loading && errorYP && (
        <Result
          status="warning"
          title="Existen algunos problemas con la operación de YoloPago"
          extra={<p>Estamos trabajando en ello.</p>}
        />
      )}
      {loading && <Loading />}
    </>
  )
}

export default YolopagoContainer
